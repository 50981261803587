import StreamerActionTypes from "./streamer-types";
import { fetchStreamerData } from "../../Services/StreamerService";

export function getStreamerItems(state) {
    return dispatch => {
        fetchStreamerData(state).then(data => {
            dispatch({
                type: StreamerActionTypes.GET_STREAMER_DATA,
                payload: data
            });
        });
    };
}

export function setStreamerItems(datas) {
    return dispatch => {
        dispatch({
            type: StreamerActionTypes.SET_STREAMER_DATA,
            payload: datas
        });
    };
}

export function setSortOption(option) {
    return dispatch => {
        dispatch({
            type: StreamerActionTypes.SET_SORT_OPTION,
            payload: option
        });
    };
}

export function addScrollCount() {
    return (dispatch) => {
        dispatch({ type: StreamerActionTypes.ADD_SCROLL_COUNT });
    }
}

export const ToggleLoading = () => {
    return ({
        type: StreamerActionTypes.TOGGLE_LOADING
    });
}