import React, { Suspense } from "react";
import "./App.scss";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { lazyRetry  } from "./Utils/lazyUtil";

import Intro from "./components/Intro/Intro";
import Header from "./components/Header/Header";
import Spinner from "./components/Spinner/Spinner";

import { setCurrentUser, ToggleFirstLoading } from "./Redux/User/user-actions";
import { selectCurrentUser, selectToggleFirst } from "./Redux/User/user-selectors";

class App extends React.Component {
    unsubscribeFromAuth = null;

    constructor(props) {
        super(props);
        this.windowResize = this.windowResize.bind(this);
    }

    componentDidMount() {
        document.title = "홈 - 버축대 시즌2";
        window.addEventListener('resize', this.windowResize);

        const pageUpBtn = document.querySelector('.page-top');

        // Show or hide the button based on scroll position
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                pageUpBtn.style.display = 'block';
                pageUpBtn.style.opacity = '1';
            } else {
                pageUpBtn.style.opacity = '0';
            }
        });

        // Scroll to the top when the button is clicked
        pageUpBtn.addEventListener('click', () => {
            window.scrollTo({
                top: 0
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
    }

    windowResize = () => {
    }

    render() {
        return (
            <div>
                {
                    this.props.firstLoading ?
                        <div className="start_container" style={{ display: this.props.firstLoading ? "block" : "none" }}>
                            <Intro />
                        </div>
                        : null
                }
                <div style={{ display: this.props.firstLoading ? "none" : "block" }}>
                    <Header />
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                            <Route exact path="/" component={TeamComponent} />
                            <Route exact path="/all" component={TeamComponent} />
                            <Route exact path="/red" component={TeamRedComponent} />
                            <Route exact path="/black" component={TeamBlackComponent} />
                            <Route exact path="/salt" component={TeamSaltComponent} />
                            <Route exact path="/half" component={TeamHalfComponent} />
                            <Route exact path="/impact" component={TeamImpactComponent} />
                            <Route exact path="/result" component={GameResultComponent} />
                        </Switch>
                    </Suspense>
                </div>
                <div className="page-top">
                    <a></a>
                </div>
            </div>
        );
    }
}

const TeamComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "Team" */ './Pages/Team/Team'), "Team"));
const TeamRedComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "TeamRed" */ './Pages/Team/TeamRed'), "TeamRed"));
const TeamBlackComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "TeamBlack" */ './Pages/Team/TeamBlack'), "TeamBlack"));
const TeamSaltComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "TeamSalt" */ './Pages/Team/TeamSalt'), "TeamSalt"));
const TeamHalfComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "TeamHalf" */ './Pages/Team/TeamHalf'), "TeamHalf"));
const TeamImpactComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "TeamImpact" */ './Pages/Team/TeamImpact'), "TeamImpact"));
const GameResultComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "GameResult" */ './Pages/GameResult/GameResult'), "GameResult"));


const mapStateToProps = state => ({
    currentUser: selectCurrentUser(state),
    firstLoading: selectToggleFirst(state),
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    ToggleFirstLoading: () => dispatch(ToggleFirstLoading()),
});

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(App);