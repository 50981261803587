import GameActionTypes from './game-types';

const INITIAL_STATE = {
    gameItems: [],
    scoreItems: [],
    teamItems: [],
    goalItems: [],
    assistItems: [],
    pointItems: []
}

const gameReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
	switch(type) {
        case GameActionTypes.GET_GAME_DATA: {
            return { ...state, gameItems: payload };
        }
        case GameActionTypes.GET_SCORE_DATA: {
            return { ...state, scoreItems: payload };
        }
        case GameActionTypes.GET_TEAM_DATA: {
            return { ...state, teamItems: payload };
        }
        case GameActionTypes.GET_GOAL_DATA: {
            return { ...state, goalItems: payload };
        }
        case GameActionTypes.GET_ASSIST_DATA: {
            return { ...state, assistItems: payload };
        }
        case GameActionTypes.GET_POINT_DATA: {
            return { ...state, pointItems: payload };
        }
        default:
            return state;
	}
}

export default gameReducer;