import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./NavMenu.scss";
import { withRouter } from "react-router";
import { selectCurrentUser } from "../../Redux/User/user-selectors";
import { connect } from "react-redux";
import { compose } from "redux";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleMenuHidden } from "../../Redux/User/user-actions";
import { setSortOption } from "../../Redux/Streamer/streamer-actions";

const NavMenu = ({ ToggleMenuHidden }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const options = document.querySelectorAll('.overlay__option');
        options.forEach(option => {
            option.addEventListener('click', () => {
                dispatch(setSortOption(1));
            });
        });
    }, []);

  return (
    <div className="overlay">
      <div className="overlay__exit" onClick={ToggleMenuHidden}>
        <FontAwesomeIcon icon={faTimes} className="overlay__exit-icon" />
      </div>
      <div className="overlay__content" onClick={ToggleMenuHidden}>
        <Link className="overlay__option" to="/all">
            전체
        </Link>
        <Link className="overlay__option" to="/red">
            빨간양념
        </Link>

        <Link className="overlay__option" to="/black">
            까만양념
        </Link>

        <Link className="overlay__option" to="/salt">
            소금구이
        </Link>

        <Link className="overlay__option" to="/half">
            반반숯불
        </Link>

        <Link className="overlay__option" to="/impact">
            BEST 11
        </Link>

        <Link className="overlay__option" to="/result">
            경기결과
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  ToggleMenuHidden: () => dispatch(ToggleMenuHidden())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavMenu);
